import "./lib/globals"
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { ActivityIndicator, FlatList, StyleSheet, Text, View, Image, ImageSourcePropType, ScrollView, RefreshControl, Platform } from 'react-native';
import { LinearGradient } from "expo-linear-gradient"
import { cryptoIcons } from "./lib/cryptocurrency-icons"
import coins from "./assets/coins.json"
import { compact, formatNumber, sum } from './lib/helpers'
import * as cctx from 'ccxt'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Menu from "./components/menu";
import { SafeAreaView } from "react-native-safe-area-context"
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { StackNavigationProp } from "@react-navigation/stack";

export type RootStackParamList = {
  Portfolio: undefined
  Vault: undefined
  Import: undefined
  "New Wallet": undefined
  Wallet: { name: string }
  Advanced: undefined
}

export type NavigationType = StackNavigationProp<RootStackParamList, keyof RootStackParamList>

interface ILayoutProps {
  navigation: NavigationType
  refreshControl?: any
  displayVaultIcon?: boolean
}

interface ILayoutState {
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  scrollViewContainer: {
  },
  wrapper: {
    maxWidth: 500,
    alignSelf: "center",
    width: "100%",
    flex: 1,
  },
})
const containerGradient = ["#172632", "#0B131B"]

export default class Layout extends React.Component<ILayoutProps, ILayoutState> {
  static defaultProps: Partial<ILayoutProps> = {
    displayVaultIcon: false,
  }

  render() {
    return (
      <LinearGradient
        colors={containerGradient}
        style={styles.container}>
        <SafeAreaView style={styles.main}>
            {this.props.displayVaultIcon && <Menu navigation={this.props.navigation} />}
            <ScrollView
            contentContainerStyle={styles.scrollViewContainer}
            refreshControl={this.props.refreshControl}>
              <View style={styles.wrapper}>
                {this.props.children}
              </View>
            </ScrollView>
        </SafeAreaView>
      </LinearGradient>
    );
  }
}
