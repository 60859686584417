import AsyncStorage from "@react-native-async-storage/async-storage"
import { IAsset } from "../types/asset"
import { Dictionary } from "../types/dictionary"
import { IExchangeInfo } from "../types/exchange-info"
import { IWallet, IWalletWithName } from "../types/wallet"

export async function getAssets() {
  const wallets = await getWallets()

  return wallets.reduce((assets, wallet) => {
    for (const a of wallet.assets) {
      let asset = assets.find((aa) => a.symbol === aa.symbol)

      if (asset) {
        asset.amount += a.amount
      } else {
        assets.push(Object.assign({}, a))
      }
    }

    return assets
  }, [] as IAsset[])
}

export async function getWallets() {
  let vault: Dictionary<IWallet> = {}
  try {
    vault = JSON.parse(await AsyncStorage.getItem("vault") || "{}")
  } catch (e) {
    // TODO: maybe error handle?
    return []
  }

  return Object.keys(vault).map((name) => {
    const wallet = vault[name]
    const walletWithName: IWalletWithName = Object.assign({
      name,
    }, wallet)

    return walletWithName
  })
}

type Vault = Dictionary<IWallet>

export async function getVault() {
  try {
    const vault: Vault = JSON.parse(await AsyncStorage.getItem("vault") || "{}")

    return vault
  } catch (e) {
    // TODO: maybe error handle?
    return {}
  }
}

export async function storeWallet(name: string, assets: IAsset[], exchange?: IExchangeInfo) {
  const vault = await getVault()

  const wallet: IWallet = {
    assets,
    exchange,
  }

  vault[name] = wallet
  await storeVault(vault)
}

export async function removeWallet(name: string) {
  const vault = await getVault()
  delete vault[name]
  await storeVault(vault)
}

export async function storeVault(vault: Vault) {
  try {
    const jsonValue = JSON.stringify(vault)
    await AsyncStorage.setItem('vault', jsonValue)
  } catch (e) {
    console.error(e)
    // TODO: handle
    // saving error
  }
}

export async function getWallet(name: string) {
  try {
    const jsonValue = await AsyncStorage.getItem('vault')
    return jsonValue != null ? JSON.parse(jsonValue)[name] as IWallet : null
  } catch(e) {
    // TODO: handle
    // error reading value
  }
}