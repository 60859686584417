import React from "react"
import { Platform, View } from "react-native"
import { cryptoIcons } from "./lib/cryptocurrency-icons"
import {
  findById,
  findBySymbol,
  sanitizeSymbol,
} from "./repositories/coin-repository"
import { getWallets, storeWallet } from "./repositories/wallet-repository"
import { IAsset } from "./types/asset"
import * as cctx from "ccxt"
import { StackNavigationProp } from "@react-navigation/stack"
import { ExchangeType } from "./types/exchange-info"
import Layout, { RootStackParamList } from "./Layout"
import {
  Button,
  RadioButton,
  Snackbar,
  TextInput,
  Caption,
  Colors,
  HelperText,
} from "react-native-paper"
import { compact, isBlank, sleep } from "./lib/helpers"
import {
  ALREADY_EXIST,
  isValid,
  IS_REQUIRED,
  pushError,
  setFieldAndResetErrors,
  ValidationErrors,
} from "./lib/validations"
import { ErrorList } from "./components/error-list"
import {
  getAssetsFromExchange,
  importAssetsFromExchange,
} from "./repositories/exchange-repository"

type ImportScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Import"
>

interface IImportProps {
  navigation: ImportScreenNavigationProp
}

// Implements IExchangeInfo
interface IImportState {
  name: string
  exchange: ExchangeType
  apiKey: string
  secret: string
  isImporting: boolean
  error?: string

  validationErrors: ValidationErrors<IImportState>
}

export default class ImportScreen extends React.Component<
  IImportProps,
  IImportState
> {
  constructor(props: IImportProps) {
    super(props)

    this.state = {
      name: "",
      apiKey: "",
      secret: "",
      exchange: "binance",
      isImporting: false,
      error: undefined,
      validationErrors: {},
    }
  }

  async validate() {
    const { state } = this
    const { name, apiKey, secret } = state

    let validationErrors: ValidationErrors<IImportState> = {}

    const wallets = await getWallets()

    if (isBlank(name)) {
      pushError(validationErrors, "name", IS_REQUIRED)
    } else {
      if (wallets.find((w) => w.name === name)) {
        pushError(validationErrors, "name", ALREADY_EXIST)
      }
    }

    if (isBlank(apiKey)) {
      pushError(validationErrors, "apiKey", IS_REQUIRED)
    } else {
      if (wallets.find((w) => w.exchange?.apiKey === apiKey)) {
        pushError(validationErrors, "apiKey", ALREADY_EXIST)
      }
    }

    if (isBlank(secret)) {
      pushError(validationErrors, "secret", IS_REQUIRED)
    }

    this.setState({
      validationErrors,
    })

    return isValid(validationErrors)
  }

  async import() {
    const { name, exchange, apiKey, secret } = this.state

    const isValid = await this.validate()
    if (!isValid) {
      return
    }

    try {
      this.setState({
        isImporting: true,
      })

      await importAssetsFromExchange(name, { exchange, apiKey, secret })

      this.setState({
        name: "",
        apiKey: "",
        secret: "",
        exchange: "binance",
        isImporting: false,
      })

      if (this.props.navigation.isFocused()) {
        this.props.navigation.pop()
      }
    } catch (err) {
      this.setState({
        error: err.message,
        isImporting: false,
      })

      throw err
    }
  }

  render() {
    const { validationErrors } = this.state

    return (
      <Layout navigation={this.props.navigation}>
        <View style={{ height: 5 }} />

        <View
          style={{ backgroundColor: "#191B22", borderRadius: 5, padding: 10 }}
        >
          <Caption>Exchange</Caption>
          <RadioButton.Group
            onValueChange={(value) =>
              this.setState({ exchange: value as ExchangeType })
            }
            value={this.state.exchange}
          >
            <RadioButton.Item label="Binance" value="binance" />
            {/* <RadioButton.Item label="Kraken" value="kraken" /> */}
          </RadioButton.Group>
        </View>

        <View style={{ height: 5 }} />

        <TextInput
          label="Name"
          onChangeText={(text) =>
            this.setState(setFieldAndResetErrors(this.state, "name", text))
          }
          value={this.state.name}
          mode="outlined"
          error={!!validationErrors.name}
        />
        <ErrorList errors={validationErrors.name} />

        <View style={{ height: 5 }} />

        <TextInput
          label="API Key"
          onChangeText={(text) =>
            this.setState(setFieldAndResetErrors(this.state, "apiKey", text))
          }
          value={this.state.apiKey}
          mode="outlined"
          error={!!validationErrors.apiKey}
        />
        <ErrorList errors={validationErrors.apiKey} />

        <View style={{ height: 5 }} />

        <TextInput
          label="Secret"
          onChangeText={(text) =>
            this.setState(setFieldAndResetErrors(this.state, "secret", text))
          }
          value={this.state.secret}
          mode="outlined"
          error={!!validationErrors.secret}
        />
        <ErrorList errors={validationErrors.secret} />

        <View style={{ height: 5 }} />

        <Button
          onPress={() => this.import()}
          mode="contained"
          icon="application-import"
          loading={this.state.isImporting}
          disabled={this.state.isImporting}
        >
          Import
        </Button>

        <Snackbar
          visible={!!this.state.error}
          onDismiss={() => this.setState({ error: undefined })}
          theme={{
            colors: {
              onSurface: Colors.pink300,
            },
          }}
        >
          {this.state.error}
        </Snackbar>
      </Layout>
    )
  }
}
