import AsyncStorage from "@react-native-async-storage/async-storage"
import coins from "../assets/coins.json"
import { ICoinGeckoMarketItem } from "../types/coin"

export function findBySymbol(symbol: string) {
  return coins.find((coin) => coin.symbol.toLowerCase() === symbol.toLowerCase())
}

export function findById(id: string) {
  return coins.find((coin) => coin.id.toLowerCase() === id.toLowerCase())
}

export function symbolToId(symbol: string) {
  return findBySymbol(symbol)?.id
}

export function isSymbolValid(symbol: string) {
  return !!findBySymbol(symbol)
}

export function sanitizeSymbol(symbol: string) {
  return symbol.trim().toLowerCase()
}

export async function getCachedPrices() {
  let prices: ICoinGeckoMarketItem[] = []

  try {
    prices = JSON.parse(await AsyncStorage.getItem("prices") || "[]")
    return prices
  } catch (e) {
    return []
  }
}

export async function setCachedPrices(coins: ICoinGeckoMarketItem[]) {
  try {
    const jsonValue = JSON.stringify(coins)
    await AsyncStorage.setItem("prices", jsonValue)
  } catch (e) {
    // TODO: handle
    // saving error
  }
}