export function compact(arr: any[]) {
  return arr.filter(x => !!x)
}

export function formatNumber(num: number) {
  return Number(num)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function sum(arr: number[]) {
  if (arr.length === 0) {
    return 0
  }

  return arr.reduce((a, b) => a + b)
}

export function toNumber(str: string = "") {
  return parseFloat(str.replace(/\s/g, ""))
}

export function sleep(ms: number) {
  return new Promise((resolve, reject) => setTimeout(resolve, ms))
}

export async function fetchWithTimeout(url: string, timeout: number, init?: RequestInit) {
  const abortController = new AbortController()
  const signal = abortController.signal

  setTimeout(() => abortController.abort(), timeout)

  return fetch(url, Object.assign({ signal }, init))
}

export function isBlank(str?: string) {
  return (!str || /^\s*$/.test(str));
}

export function isNumber(str?: string) {
  return !isNaN(toNumber(str))
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}