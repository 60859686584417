import { StackNavigationProp } from "@react-navigation/stack"
import React, { Component } from "react"
import { View, FlatList, StyleSheet } from "react-native"
import Layout, { RootStackParamList } from "./Layout"
import { List, Menu, IconButton, Colors, Button, Snackbar, Portal } from "react-native-paper"
import { getWallets, removeWallet } from "./repositories/wallet-repository"
import { IExchangeInfo } from "./types/exchange-info"
import { IWalletWithName } from "./types/wallet"
import { useIsFocused } from "@react-navigation/native"
import { importAssetsFromExchange } from "./repositories/exchange-repository"

type VaultScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Vault"
>

interface IVaultProps {
  navigation: VaultScreenNavigationProp
  isFocused: boolean
}

interface IVaultState {
  wallets: IWalletWithName[]
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginVertical: 10,
  },
  button: {
    marginHorizontal: 14,
  },
})

interface IWalletItemProps {
  vault: IVaultProps
  name: string
  exchange?: IExchangeInfo
  onRemove: () => void
}

interface IWalletItemState {
  visible: boolean
  isSyncing: boolean
  flash: IFlashMessage[]
}

enum FlashMessageType {
  info,
  error
}

interface IFlashMessage {
  type: FlashMessageType
  text: string
}

class WalletItem extends Component<IWalletItemProps, IWalletItemState> {
  constructor(props: IWalletItemProps) {
    super(props)

    this.state = {
      visible: false,
      isSyncing: false,
      flash: [],
    }
  }

  async sync() {
    const { name, exchange } = this.props
    if (!exchange) {
      return
    }

    this.setState({
      visible: false,
      isSyncing: true,
    })

    try {
      await importAssetsFromExchange(name, exchange)

      const flashMessages = [...this.state.flash, {
        text: `Wallet ${name} synced successfuly.`,
        type: FlashMessageType.info,
      }]
      this.setState({
        flash: flashMessages,
      })
    } catch (err) {
      const flashMessages = [...this.state.flash, {
        text: err.message,
        type: FlashMessageType.error,
      }]
      this.setState({
        flash: flashMessages,
      })

      throw err
    } finally {
      this.setState({
        isSyncing: false,
      })
    }
  }

  render() {
    const { vault, name, exchange, onRemove } = this.props
    let description = ""

    if (exchange) {
      description += "Exchange: " + exchange.exchange
    }

    const openMenu = () => this.setState({ visible: true })
    const closeMenu = () => this.setState({ visible: false })

    return (
      <View>
        <List.Item
          title={name}
          description={description}
          onPress={() => vault.navigation.navigate("Wallet", { name })}
          left={(props) => (
            <List.Icon
              {...props}
              icon={exchange ? "cloud" : "folder"}
              style={{ justifyContent: "center" }}
            />
          )} // TODO "folder" for local
          right={(props) => (
            <Menu
              visible={this.state.visible}
              onDismiss={() => closeMenu()}
              anchor={
                <IconButton
                  icon="cog"
                  color={Colors.purple300}
                  onPress={() => openMenu()}
                />
              }
            >
              {exchange && <Menu.Item onPress={() => this.sync()} title="Sync" icon="sync" />}
              <Menu.Item onPress={() => onRemove()} title="Remove" icon="delete" />
            </Menu>
          )}
        />

        <Portal>
          {this.state.flash.map((flash) => (
            <Snackbar
              visible={true}
              onDismiss={() => {
                const flashMessages = this.state.flash.filter((f) => flash !== f)
                this.setState({ flash: flashMessages })
              }}
              theme={{
                colors: {
                  onSurface: flash.type === FlashMessageType.info ? Colors.green200 : Colors.pink300,
                },
              }}
            >
              {flash.text}
            </Snackbar>
          ))}
        </Portal>
      </View>
    )
  }
}

class VaultScreen extends React.Component<IVaultProps, IVaultState> {
  constructor(props: IVaultProps) {
    super(props)

    this.state = {
      wallets: [],
    }
  }

  async componentDidMount() {
    await this.refreshWallets()
  }

  async componentDidUpdate(prevProps: IVaultProps) {
    if (prevProps.isFocused !== this.props.isFocused) {
      await this.refreshWallets()
    }
  }

  async refreshWallets() {
    const wallets = await getWallets()

    this.setState({
      wallets,
    })
  }

  async removeWallet(name: string) {
    await removeWallet(name)

    await this.refreshWallets()
  }

  render() {
    const { navigation } = this.props
    return (
      <Layout navigation={this.props.navigation}>
        <View style={{ height: 5 }} />

        <View
          style={styles.buttonRow}
        >
          <Button
            icon="plus"
            mode="contained"
            onPress={() => navigation.navigate("New Wallet")}
            style={styles.button}
          >
            Wallet
          </Button>

          <Button
            icon="application-import"
            mode="contained"
            onPress={() => navigation.navigate("Import")}
            style={styles.button}
          >
            Import
          </Button>
          <View style={{flex: 1}} />

          <IconButton
            icon="cog"
            onPress={() => navigation.navigate("Advanced")}
            style={styles.button}
          />
        </View>

        {this.state.wallets.map((wallet, idx) => (
          <WalletItem
            vault={this.props}
            name={wallet.name}
            exchange={wallet.exchange}
            onRemove={() => this.removeWallet(wallet.name)}
            key={idx}
          />
        ))}
      </Layout>
    )
  }
}

export default function (props: Omit<IVaultProps, "isFocused">) {
  const isFocused = useIsFocused()

  return <VaultScreen {...props} isFocused={isFocused} />
}
