export const IS_REQUIRED = "This value is required."
export const IS_NAN = "Value is not a number."
export const ALREADY_EXIST = "Wallet with this value already exists."
export const SYMBOL_IS_INVALID = "Invalid symbol."

export type ErrorListType = string[]

export type ValidationErrors<S> = {
  [key in Exclude<keyof S, "validationErrors">]?: ErrorListType
}

export function pushError<S>(validationErrors: ValidationErrors<S>, key: keyof ValidationErrors<S>, value: string) {
  validationErrors[key] = validationErrors[key] || []
  validationErrors[key]!.push(value)
}

export function isValid<S>(validationErrors: ValidationErrors<S>) {
  return Object.keys(validationErrors).length === 0
}

interface StateWithValidationErrors<S> {
  validationErrors: ValidationErrors<S>
}

export function setFieldAndResetErrors<S extends StateWithValidationErrors<S>, K extends keyof ValidationErrors<S>>(state: S, key: keyof ValidationErrors<S>, value: S[K]) {
  const { validationErrors } = state
  delete validationErrors[key]
  state[key] = value

  return state
}