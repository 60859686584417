import "./lib/globals"
import Constants from 'expo-constants';
import React from 'react';
import { ActivityIndicator, FlatList, StyleSheet, Text, View, Image, ImageSourcePropType, ScrollView, RefreshControl, Platform } from 'react-native';
import { compact, fetchWithTimeout, formatNumber, sleep, sum } from './lib/helpers'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DrawerNavigationProp } from '@react-navigation/drawer';
import Layout, { RootStackParamList } from "./Layout";
import { StackNavigationProp } from "@react-navigation/stack";
import Menu from "./components/menu";
import { IAsset, IAssetWithPrice } from "./types/asset";
import { getCachedPrices, setCachedPrices, symbolToId } from "./repositories/coin-repository";
import { getAssets, getWallet } from "./repositories/wallet-repository";
import { ICoinGeckoMarketItem } from "./types/coin";
import { Colors } from "react-native-paper";
import { useIsFocused } from "@react-navigation/native";

const CURRENCY = "usd"
const SYMBOL = "$"

type ProfileScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Portfolio"
>

interface IPortfolioProps {
  navigation: ProfileScreenNavigationProp
  isFocused: boolean
}

interface IPortfolioState {
  // isLoading: boolean
  isRefreshing: boolean
  assets: (IAsset | IAssetWithPrice)[]
  total: number
  totalChange: number
  percentChange: number
}

const COLOR_RED = Colors.pink500
const COLOR_GREEN = Colors.green500

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // marginTop: Constants.statusBarHeight || 0,
  },
  wrapper: {
    maxWidth: 500,
    alignSelf: "center",
    width: "100%",
    flex: 3,
  },
  totalColumn: {
    flexDirection: "column",
    alignItems: "center",
    paddingVertical: 40,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#394147",
  },
  symbol: {
    color: "#ffffff",
    fontSize: 32,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  spacer: {
    flex: 1,
  },
  amountColumn: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
  totalRow: {
    flexDirection: "row",
  },
  fiatSymbol: {
    color: "#A7ABB0",
    fontSize: 22,
    fontWeight: "bold",
  },
  total: {
    color: "#ffffff",
    fontSize: 32,
    fontWeight: "bold",
  },
  raising: {
    color: COLOR_GREEN,
  },
  falling: {
    color: COLOR_RED,
  },
  amount: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "bold",
  },
  price: {
    color: "#999999",
    fontSize: 14,
  },
  priceChangeRow: {
    flexDirection: "row",
    marginTop: 3,
  },
  percentChange: {
    fontSize: 13,
    fontWeight: "bold",
  },
  priceChange: {
    fontSize: 13,
    opacity: 0.9,
    marginRight: 10,
  },
  cryptoIcon: {
    height: 32,
    width: 32,
    marginRight: 20,
  },
  scrollView: {
  },
})

function mergePricesAndSort(assets: IAsset[], coins: ICoinGeckoMarketItem[]) {
  return assets.reduce((results: (IAsset | IAssetWithPrice)[], asset) => {
    const { symbol } = asset
    const coin = coins.find((c) => c.symbol.toLowerCase() === symbol)

    if (coin) {
      const assetWithPrice: IAssetWithPrice = Object.assign({}, asset, {
        currency: "usd",
        price: coin.current_price,
        total: asset.amount * coin.current_price,
        totalChange: asset.amount * coin.price_change_24h,
        icon: { uri: coin.image },
        coin,
      })

      results.push(assetWithPrice)
    } else {
      results.push(asset)
    }

    return results
  }, [])
  .sort((a, b) => ("total" in b ? b.total : 0) - ("total" in a ? a.total : 0))
}

const Total = ({ total }: { total: number }) =>
  <View style={styles.totalRow}>
    <Text style={styles.fiatSymbol}>{SYMBOL}</Text>
    <Text style={styles.total}>{formatNumber(total)}</Text>
  </View>

const PercentChange = ({ amount }: { amount: number }) => {
  return (
    <Text style={[amount < 0 ? styles.falling : styles.raising, styles.percentChange]}>{formatNumber(amount)}%</Text>
  )
}

const PriceChange = ({ symbol, amount }: { symbol: string, amount: number }) => {
  return (
    <Text style={[amount < 0 ? styles.falling : styles.raising, styles.priceChange]}>{symbol}{formatNumber(amount)}</Text>
  )
}

const Asset = ({ item }: { item: IAsset | IAssetWithPrice }) => {
  return <View style={styles.item}>
    <View style={styles.amountColumn}>
      {"price" in item && <Total total={item.total} />}
      <View style={{flexDirection: "row", alignItems: "center"}}>
        {"coin" in item && <Text style={styles.price}>{formatNumber(item.coin.current_price)} x </Text>}
        <Text style={styles.amount}>{formatNumber(item.amount)}</Text>
      </View>

      {"coin" in item && <View style={styles.priceChangeRow}>
        <PriceChange symbol={SYMBOL} amount={item.totalChange} />
        <PercentChange amount={item.coin.price_change_percentage_24h} />
      </View>}
    </View>

    <View style={styles.spacer}></View>

    {item.icon && <Image style={styles.cryptoIcon} source={item.icon} />}

    <Text style={styles.symbol}>{item.symbol}</Text>
  </View>
}

class PortfolioScreen extends React.Component<IPortfolioProps, IPortfolioState> {
  constructor(props: IPortfolioProps) {
    super(props)

    this.state = {
      // isLoading: true,
      isRefreshing: false,
      assets: [],
      total: 0,
      totalChange: 0,
      percentChange: 0,
    }
  }

  async loadAssetsAndFetchPrices() {
    await this.loadAssets()
    await this.fetchPrices()
  }

  async loadAssets() {
    const coins = await getCachedPrices()
    if (coins.length > 0) {
      await this.setPrices(coins)
    } else {
      const assets = await getAssets()
      this.setState({
        assets
      })
    }
  }

  async setPrices(coins: ICoinGeckoMarketItem[]) {
    let assets = await getAssets()
    const assetsWithPrices = mergePricesAndSort(assets, coins)
    const total = sum(compact(assetsWithPrices.map((asset) => "total" in asset ? asset.total : 0)))
    const totalChange = sum(compact(assetsWithPrices.map((asset) => "totalChange" in asset ? asset.totalChange : 0)))
    const percentChange = totalChange / total * 100

    this.setState({
      assets: assetsWithPrices,
      total,
      totalChange,
      percentChange: isNaN(percentChange) ? 0 : percentChange,
    })
  }

  async componentDidMount() {
    await this.loadAssetsAndFetchPrices()
  }

  async componentDidUpdate(prevProps: IPortfolioProps) {
    if (prevProps.isFocused !== this.props.isFocused) {
      await this.loadAssetsAndFetchPrices()
    }
  }

  async fetchPrices() {
    this.setState({
      isRefreshing: true,
    })

    let assets = await getAssets()
    const ids = compact(assets.map((asset) => symbolToId(asset.symbol)))

    try {
      const response = await fetchWithTimeout(`https://api.coingecko.com/api/v3/coins/markets?include_24hr_change=true&vs_currency=${CURRENCY}&ids=${ids.join(",")}`, 5000)
      const coins = await response.json() as ICoinGeckoMarketItem[]
      await setCachedPrices(coins)
      await this.setPrices(coins)
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({
        isRefreshing: false,
      })
    }
  }

  render() {
    return (
      <Layout navigation={this.props.navigation} refreshControl={<RefreshControl refreshing={this.state.isRefreshing} onRefresh={() => this.loadAssetsAndFetchPrices()} />} displayVaultIcon={true}>
        <View style={styles.totalColumn}>
          <Text style={styles.amount}>Total</Text>
          <Text style={styles.total}>{SYMBOL}{formatNumber(this.state.total)}</Text>

          <View style={styles.priceChangeRow}>
            <PriceChange symbol={SYMBOL} amount={this.state.totalChange} />
            <PercentChange amount={this.state.percentChange} />
          </View>
        </View>

        {this.state.assets.map((asset, idx) => (
          <Asset item={asset} key={idx} />
        ))}
      </Layout>
    )
  }
}

export default function (props: Omit<IPortfolioProps, "isFocused">) {
  const isFocused = useIsFocused()

  return <PortfolioScreen {...props} isFocused={isFocused} />
}
