import "./lib/globals"
import React from 'react';
import { DarkTheme, NavigationContainer } from '@react-navigation/native';
import PortfolioScreen from "./Portfolio";
import { SafeAreaProvider } from 'react-native-safe-area-context'
import VaultScreen from "./VaultScreen";
import { StyleSheet } from "react-native";
import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack';
import { Provider as PaperProvider, DarkTheme as PaperDarkTheme } from 'react-native-paper';
import themes from "./constants/themes";
import { Appbar } from 'react-native-paper';
import { screensEnabled } from "react-native-screens";
import ImportScreen from "./ImportScreen";
import WalletScreen from "./WalletScreen";
import AdvancedScreen from "./AdvancedScreen";

interface IAppProps {
}

interface IAppState {
}

function CustomNavigationBar({ navigation, previous, scene }: StackHeaderProps) {
  return (
    <Appbar.Header>
       {previous ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={scene.route.name} />
    </Appbar.Header>
  );
}

export default class App extends React.Component<IAppProps, IAppState> {
  render() {
    const Stack = createStackNavigator();

    return (
      <SafeAreaProvider>
        <PaperProvider theme={themes.dark}>
          <NavigationContainer theme={themes.dark}>
          <Stack.Navigator
            screenOptions={({ route, navigation }) => ({
              headerShown: route.name !== "Portfolio",
              header: (props) => <CustomNavigationBar {...props} />,
            })}
            // initialRouteName="Vault"
            // initialRouteName="Import"
            // initialRouteName="New Wallet"
            // initialRouteName="Advanced"
          >
            <Stack.Screen name="Portfolio" component={PortfolioScreen} />
            <Stack.Screen name="Vault" component={VaultScreen} />
            <Stack.Screen name="Import" component={ImportScreen} />
            <Stack.Screen name="Wallet" component={WalletScreen} />
            <Stack.Screen name="New Wallet" component={WalletScreen} />
            <Stack.Screen name="Advanced" component={AdvancedScreen} />
          </Stack.Navigator>
          </NavigationContainer>
        </PaperProvider>
      </SafeAreaProvider>
    )
  }
}
