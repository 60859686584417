import React, { Component, createContext, PureComponent } from "react"
import { FlatList, View } from "react-native"
import {
  getWallet,
  getWallets,
  removeWallet,
  storeWallet,
} from "./repositories/wallet-repository"
import { StackNavigationProp } from "@react-navigation/stack"
import Layout, { RootStackParamList } from "./Layout"
import {
  Button,
  Colors,
  FAB,
  IconButton,
  Portal,
  Surface,
  TextInput,
} from "react-native-paper"
import { clone, isBlank, isNumber, toNumber } from "./lib/helpers"
import {
  ALREADY_EXIST,
  isValid,
  IS_NAN,
  IS_REQUIRED,
  pushError,
  setFieldAndResetErrors,
  SYMBOL_IS_INVALID,
  ValidationErrors,
} from "./lib/validations"
import { IAsset } from "./types/asset"
import { StyleSheet } from "react-native"
import { ErrorList } from "./components/error-list"
import { RouteProp } from "@react-navigation/native"
import { useIsFocused } from "@react-navigation/native"
import { isSymbolValid, sanitizeSymbol } from "./repositories/coin-repository"
import { IExchangeInfo } from "./types/exchange-info"

type NewWalletNavigationProp = StackNavigationProp<
  RootStackParamList,
  "New Wallet"
>

export interface INewAsset {
  symbol: string
  amount: string

  validationErrors: ValidationErrors<INewAsset>
}

type WalletScreenRouteProp =
  | RouteProp<RootStackParamList, "Wallet">
  | RouteProp<RootStackParamList, "New Wallet">

interface INewWalletProps {
  navigation: NewWalletNavigationProp
  route: WalletScreenRouteProp
  isFocused: boolean
}

interface INewWalletState {
  name: string
  assets: INewAsset[]
  exchange?: IExchangeInfo
  validationErrors: ValidationErrors<INewWalletState>
}

const styles = StyleSheet.create({
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
  },
  surface: {
    paddingBottom: 4,
    // height: 80,
    // width: 80,
    marginBottom: 5,
    justifyContent: "space-evenly",
    flexDirection: "row",
    elevation: 8,
  },
  inputColumn: {
    flex: 1,
    marginRight: 5,
  }
})

type SymbolChangeFn = (idx: number, symbol: string) => void
type AmountChangeFn = (idx: number, amount: string) => void
type AssetRemoveFn = (idx: number) => void

interface IAssetProps {
  idx: number
  symbol: string
  amount: string
  validationErrors: ValidationErrors<INewAsset>
  onSymbolChange: SymbolChangeFn
  onAmountChange: AmountChangeFn
  onRemove: AssetRemoveFn
}

interface IAssetState {}

// interface IContextProps {
//   assets: INewAsset[]
// }

// export const FormContext = createContext<IContextProps>({
//   assets: [],
// })

function sanitizeAssets(assets: INewAsset[]): IAsset[] {
  return assets.map((asset) => {
    return {
      symbol: sanitizeSymbol(asset.symbol),
      amount: toNumber(asset.amount),
    }
  })
}

class Asset extends Component<IAssetProps, IAssetState> {
  // constructor(props: IAssetProps) {
  //   super(props)
  // }
  // static contextType = FormContext

  shouldComponentUpdate(props: IAssetProps, state: IAssetState) {
    return (
      props.symbol !== this.props.symbol ||
      props.amount !== this.props.amount ||
      props.idx !== this.props.idx ||
      JSON.stringify(props.validationErrors) !==
        JSON.stringify(this.props.validationErrors)
    )
  }

  render() {
    const {
      idx,
      symbol,
      amount,
      validationErrors,
      onSymbolChange,
      onAmountChange,
      onRemove,
    } = this.props

    return (
      <Surface style={styles.surface}>
        <View style={styles.inputColumn}>
          <TextInput
            label="Symbol"
            onChangeText={(text) => onSymbolChange(idx, text)}
            value={symbol}
            mode="outlined"
            style={{flex: 1}}
            error={!!validationErrors.symbol}
          />
          <ErrorList errors={validationErrors.symbol} />
        </View>

        <View style={styles.inputColumn}>
          <TextInput
            label="Amount"
            keyboardType="numeric"
            onChangeText={(text) => onAmountChange(idx, text)}
            value={amount}
            mode="outlined"
            error={!!validationErrors.amount}
          />
          <ErrorList errors={validationErrors.amount} />
        </View>

        <IconButton
          icon="minus-box"
          color={Colors.purple300}
          onPress={() => onRemove(idx)}
          style={{ marginTop: 17 }}
        />
      </Surface>
    )
  }
}

class NewWalletScreen extends React.Component<
  INewWalletProps,
  INewWalletState
> {
  constructor(props: INewWalletProps) {
    super(props)

    this.state = {
      name: "",
      validationErrors: {},
      assets: [
        // {
        //   symbol: "BTC",
        //   amount: "0.1",
        //   validationErrors: {},
        // },
        // {
        //   symbol: "ETH",
        //   amount: "1",
        //   validationErrors: {},
        // },
        // {
        //   symbol: "ADA",
        //   amount: "2900",
        //   validationErrors: {},
        // },
        // {
        //   symbol: "VRA",
        //   amount: "52000",
        //   validationErrors: {},
        // },
      ],
    }
  }

  get isEdit() {
    return this.originalName !== undefined
  }

  get originalName() {
    return this.props.route.params?.name
  }

  async loadWallet() {
    if (this.originalName) {
      const name = this.originalName
      const wallet = await getWallet(name)
      if (wallet) {
        this.setState({
          name,
          assets: wallet.assets.map((asset) => ({
            symbol: asset.symbol.toUpperCase(),
            amount: asset.amount.toString(),
            validationErrors: {},
          })),
          exchange: wallet.exchange,
        })
      }
    }
    // else {
    //   // Set default state?
    // }
  }

  async componentDidMount() {
    await this.loadWallet()
  }

  async componentDidUpdate(prevProps: INewWalletProps) {
    if (prevProps.isFocused !== this.props.isFocused) {
      await this.loadWallet()
    }
  }

  async validate() {
    const { state } = this
    const { name, assets } = state

    let validationErrors: ValidationErrors<INewWalletState> = {}

    const wallets = await getWallets()

    if (isBlank(name)) {
      pushError(validationErrors, "name", IS_REQUIRED)
    } else {
      if (wallets.find((w) => w.name === name) && name !== this.originalName) {
        pushError(validationErrors, "name", ALREADY_EXIST)
      }
    }

    for (const asset of assets) {
      const symbol = sanitizeSymbol(asset.symbol)
      asset.validationErrors = {}

      if (isBlank(symbol)) {
        pushError(asset.validationErrors, "symbol", IS_REQUIRED)
      } else if (!isSymbolValid(symbol)) {
        pushError(asset.validationErrors, "symbol", SYMBOL_IS_INVALID)
      }
      // TODO: validate that symbol does exist
      // sanitizeSymbol() and find in the coins

      if (isBlank(asset.amount)) {
        pushError(asset.validationErrors, "amount", IS_REQUIRED)
      } else if (!isNumber(asset.amount)) {
        pushError(asset.validationErrors, "amount", IS_NAN)
      }
    }

    this.setState({
      validationErrors,
      assets,
    })

    return (
      isValid(validationErrors) &&
      assets.every((asset) => isValid(asset.validationErrors))
    )
  }

  addAsset() {
    this.setState((prevState) => ({
      assets: [
        ...prevState.assets,
        {
          symbol: "",
          amount: "",
          validationErrors: {},
        },
      ],
    }))
  }

  async save() {
    const { name } = this.state

    const isValid = await this.validate()
    if (!isValid) {
      return
    }

    const assets = sanitizeAssets(this.state.assets)

    if (this.originalName) {
      await removeWallet(this.originalName)
    }

    await storeWallet(name, assets, this.state.exchange)

    this.setState({
      name: "",
      assets: [],
      exchange: undefined,
    })

    if (this.props.navigation.isFocused()) {
      this.props.navigation.pop()
    }
  }

  modifyAssetSymbol = (idx: number, symbol: string) => {
    this.setState((prevState) => {
      const assets = clone(prevState.assets)
      assets[idx].symbol = symbol
      assets[idx].validationErrors.symbol = undefined

      return {
        assets,
      }
    })
  }

  modifyAssetAmount = (idx: number, amount: string) => {
    this.setState((prevState) => {
      const assets = clone(prevState.assets)
      assets[idx].amount = amount
      assets[idx].validationErrors.amount = undefined

      return {
        assets,
      }
    })
  }

  removeAsset = (idx: number) => {
    this.setState((prevState) => {
      const assets = clone(prevState.assets)
      assets.splice(idx, 1)

      return {
        assets,
      }
    })
  }

  render() {
    const { validationErrors } = this.state

    return (
      <Layout navigation={this.props.navigation}>
        <View style={{ height: 5 }} />

        <TextInput
          label="Name"
          onChangeText={(text) =>
            this.setState(setFieldAndResetErrors(this.state, "name", text))
          }
          value={this.state.name}
          mode="outlined"
          error={!!validationErrors.name}
        />
        <ErrorList errors={validationErrors.name} />

        <View style={{ height: 5 }} />

        {this.state.assets.map((asset, idx) => (
          <Asset
            idx={idx}
            symbol={asset.symbol}
            amount={asset.amount.toString()}
            validationErrors={asset.validationErrors}
            key={idx}
            onSymbolChange={this.modifyAssetSymbol}
            onAmountChange={this.modifyAssetAmount}
            onRemove={this.removeAsset}
          />
        ))}

        <Button
          onPress={() => this.save()}
          mode="contained"
          icon="content-save"
        >
          {this.isEdit ? "Save" : "Create"}
        </Button>

        <Portal>
          <FAB style={styles.fab} icon="plus" onPress={() => this.addAsset()} />
        </Portal>
      </Layout>
    )
  }
}

export default function (props: Omit<INewWalletProps, "isFocused">) {
  const isFocused = useIsFocused()

  return <NewWalletScreen {...props} isFocused={isFocused} />
}
