import React from "react"
import { HelperText } from "react-native-paper"

export const ErrorList = ({ errors }: { errors?: string[] }) => {
  if (!errors) {
    return null
  }

  return (
    <HelperText type="error" visible={true}>
      {errors.join("\n")}
    </HelperText>
  )
}
