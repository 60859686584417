import * as cctx from "ccxt"
import { Platform } from "react-native"
import { compact } from "../lib/helpers"
import { ExchangeType, IExchangeInfo } from "../types/exchange-info"
import { sanitizeSymbol } from "./coin-repository"
import { storeWallet } from "./wallet-repository"

export async function getAssetsFromExchange(
  exchange: ExchangeType,
  apiKey: string,
  secret: string
) {
  const client = new cctx[exchange]({
    apiKey,
    secret,
    enableRateLimit: true,
    proxy:
      Platform.OS === "web"
        ? "https://portfolio-api.deployment.cz/"
        : undefined,
    // proxy: Platform.OS === "web" ? "http://localhost:8080/" : undefined,
  })

  const balance = await client.fetchBalance()
  const assets = compact(
    Object.keys(balance).map((symbol) => {
      const amount = balance[symbol].total

      if (amount > 0) {
        symbol = symbol.replace(/^LD/, "")
        const asset = {
          symbol: sanitizeSymbol(symbol),
          amount: amount, //* 100 * Math.random(),
        }

        return asset
      }
    })
  )

  return assets
}

export async function importAssetsFromExchange(
  name: string,
  exchangeInfo: IExchangeInfo
) {
  const assets = await getAssetsFromExchange(
    exchangeInfo.exchange,
    exchangeInfo.apiKey,
    exchangeInfo.secret
  )

  await storeWallet(name, assets, exchangeInfo)
}
