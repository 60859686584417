import React, { Component, createContext, PureComponent } from "react"
import { FlatList, View } from "react-native"
import { getVault, getWallet, getWallets, removeWallet, storeVault, storeWallet } from "./repositories/wallet-repository"
import { StackNavigationProp } from "@react-navigation/stack"
import Layout, { RootStackParamList } from "./Layout"
import {
  Button,
  Colors,
  FAB,
  IconButton,
  Surface,
  TextInput,
} from "react-native-paper"
import { clone, isBlank, isNumber, toNumber } from "./lib/helpers"
import {
  ALREADY_EXIST,
  isValid,
  IS_NAN,
  IS_REQUIRED,
  pushError,
  setFieldAndResetErrors,
  ValidationErrors,
} from "./lib/validations"
import { IAsset } from "./types/asset"
import { StyleSheet } from "react-native";
import { ErrorList } from "./components/error-list"
import { RouteProp } from "@react-navigation/native"
import { useIsFocused } from "@react-navigation/native"
import { sanitizeSymbol } from "./repositories/coin-repository"
import { IExchangeInfo } from "./types/exchange-info"

type AdvancedNavigationProp = StackNavigationProp<
  RootStackParamList,
  "Advanced"
>

type AdvancedScreenRouteProp = RouteProp<RootStackParamList, "Advanced">

interface IAdvancedProps {
  navigation: AdvancedNavigationProp
  route: AdvancedScreenRouteProp
  isFocused: boolean
}

interface IAdvancedState {
  config: string
}

const styles = StyleSheet.create({
  // column: {
  //   display: "flex",
  //   flexDirection: "column",
  //   flex: 1,
  // },
  // textarea: {
  //   flex: 1,
  // },
})

class AdvancedScreen extends React.Component<
  IAdvancedProps,
  IAdvancedState
> {
  constructor(props: IAdvancedProps) {
    super(props)

    this.state = {
      config: ""
    }
  }

  async loadConfig() {
    const vault = await getVault()
    const config = JSON.stringify({
      vault,
    }, null, 2)

    this.setState({
      config,
    })
  }

  async componentDidMount() {
    await this.loadConfig()
  }

  async componentDidUpdate(prevProps: IAdvancedProps) {
    if (prevProps.isFocused !== this.props.isFocused) {
      await this.loadConfig()
    }
  }

  async save() {
    const config = JSON.parse(this.state.config)
    const { vault } = config

    if (vault) {
      await storeVault(vault)
    }
  }

  render() {
    return (
      <Layout navigation={this.props.navigation}>
        <View style={{ height: 5 }} />

        <Button
          onPress={() => this.save()}
          mode="contained"
          icon="content-save"
        >
          Save
        </Button>

        <View style={{ height: 5 }} />

        <TextInput
          label="Config"
          onChangeText={(text) => this.setState({ config: text })}
          value={this.state.config}
          mode="outlined"
          // style={styles.textarea}
          numberOfLines={10}
          multiline={true}
        />
      </Layout>
    )
  }
}

export default function(props: Omit<IAdvancedProps, "isFocused">) {
  const isFocused = useIsFocused()

  return <AdvancedScreen {...props} isFocused={isFocused} />;
}