import { MaterialCommunityIcons } from '@expo/vector-icons';
import { View, StyleSheet } from 'react-native';
import React from "react";
import { NavigationType } from '../Layout';
import Constants from 'expo-constants';
import { Portal } from 'react-native-paper';

interface IMenuProps {
  navigation: NavigationType
}

interface IMenuState {
}

export default class Menu extends React.Component<IMenuProps, IMenuState> {
  goToVault() {
    this.props.navigation.navigate("Vault")
  }

  render() {
    return (
      <View style={styles.menuContainer}>
        <MaterialCommunityIcons
          name="safe"
          size={32}
          color="black"
          style={styles.handle}
          onPress={() => this.goToVault()}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  menuContainer: {
    alignItems: 'center',
    // paddingTop: 20,
    position: "absolute",
    zIndex: 1,
    left: 10,
    top: Constants.statusBarHeight || 10,
  },
  handle: {
    color: "#ffffff",
  },
});

